<template>
  <v-container fluid class="fill-height">
    <v-row dense class="ma-0 pa-0">
      <v-col cols="12" md="7">
        <v-card elevation="12">
          <v-container class="fill-height ma-0 pa-0" fluid>
            <v-row class="fill-height ma-0 pa-0">
              <v-col>
                {{ $t("user.queries.homePageMsg_1") }}
                <br />
                {{ $t("user.queries.homePageMsg_2") }}
                <br />
                <span class="fontSize13px">
                  <span class="withBoldFont">{{
                    $t("user.queries.homePageMsg_3")
                  }}</span>
                  <span class="ml-1">{{
                    $t("user.queries.homePageMsg_4")
                  }}</span>
                </span>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
    <v-row dense class="fill-height ma-0 pa-0">
      <v-col>
        <GhmDataTable
          :dataList="dataList"
          :itemsPerPageProps="itemsPerPage"
          :itemsPerPageOptionsProps="itemsPerPageOptions"
          :tableType="APP_DATATABLE_TYPE_USER_LIST"
          @DownloadAsCsvFileEmitter="downloadAsCsvFileEmitter"
          @ViewDataQueryAsJsonFileEmitter="viewDataQueryAsJsonFileEmitter">
         <template v-slot:addNewRowButton>
            {{ $t('user.queries.dataQueryList') }}
          </template>
          <template
            v-slot:restApiDialogContentSlot="{
              restApiDialog,
              userActionType,
              dataQueryItem,
              closeRestApiDialog,
            }">
            <RestApiDialog
              @FetchDataQueryFromRestApiEmitter="fetchDataQueryFromRestApiEmitter"
              :restApiDialogProps="restApiDialog"
              :userActionType="userActionType"
              :dataQueryItemProps="dataQueryItem"
              :closeRestApiDialog="closeRestApiDialog"
            />
          </template>
        </GhmDataTable>
        <v-dialog
          v-model="jsonViewerDialog"
          fullscreen
          hide-overlay
          transition="dialog-bottom-transition">
          <v-card>
            <v-toolbar dark color="primary">
              <v-btn icon dark @click="jsonViewerDialog = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-toolbar-title>JSON View</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-toolbar-title>
                <v-btn class="ma-2" outlined color="white" @click="doCopy"
                  >Copy JSON</v-btn>
              </v-toolbar-title>
            </v-toolbar>
            <v-list three-line subheader>
              <v-list-item>
                <v-list-item-content>
                  <json-viewer
                    :value="dataQueryDetailList"
                    :expand-depth="0"
                    boxed
                  ></json-viewer>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import GhmDataTable from '@/components/app/GhmDataTable.vue';
import Constants from '@/constants';
import { mapActions } from 'vuex';
import RestApiDialog from '@/components/admin/RestApiDialog.vue';
import downloadAsCsvFile from '@/components/app/CreateAndDownloadCsvFile';
import FetchDataQueryApiMixin from '@/plugins/FetchDataQueryApiMixin';
import ItemsPerPageMixin from '@/plugins/ItemsPerPageMixin';
import copyTextToClipboard from '@/components/app/CopyTextToClipboard';

export default {
  name: 'UserQueriesList',

  data() {
    return {
      APP_DATATABLE_TYPE_USER_LIST: Constants.APP_DATATABLE_TYPE_USER_LIST,
      APP_ADMIN_QUERY_TYPE_SQL: Constants.APP_ADMIN_QUERY_TYPE_SQL,
      APP_ADMIN_QUERY_TYPE_JSON: Constants.APP_ADMIN_QUERY_TYPE_JSON,
      // pageTitle value should be key from i18n translation file.
      pageTitle: 'app.pageTitle.abfrageAusfuhren',
      dataList: [],
      jsonViewerDialog: false,
      USER_LIST_ACTION_TYPE_CSV_DOWNLOAD: Constants.USER_LIST_ACTION_TYPE_CSV_DOWNLOAD,
      USER_LIST_ACTION_TYPE_JSON_VIEW: Constants.USER_LIST_ACTION_TYPE_JSON_VIEW,
    };
  },

  mixins: [ItemsPerPageMixin, FetchDataQueryApiMixin],

  components: {
    GhmDataTable,
    RestApiDialog,
  },

  /**
   * Initialize data when component mounted. (page initial render)
   */
  async mounted() {
    // Fetch all data queries from DB
    await this.showAllAvailableDataQueriesForUser();
  },

  created() {
    console.log(
      '--------------user queries page tityle CREATED ---------------',
    );
    this.setPageTitleOfThisPage();
  },
  methods: {
    ...mapActions([
      'setPageTitle',
      'findDataQueryListForUser',
      'findQueryDetailForAdmin',
    ]),

    /**
     * This will copy the content in clipboard for copy paste for user.
     */
    doCopy() {
      copyTextToClipboard(JSON.stringify(this.dataQueryDetailList));
    },

    /**
     * Method to fetch all data queries for user.
     */
    async showAllAvailableDataQueriesForUser() {
      console.log(
        '------------ START : fetching showAllAvailableDataQueriesForUser ********** ',
      );
      this.dataList = await this.findDataQueryListForUser();
      if (this.dataList) {
        this.calculateItemsPerPage(this.dataList.length);
      }
      console.log(
        '------------ END : showAllAvailableDataQueriesForUser ********** ',
        this.dataList,
      );
    },

    setPageTitleOfThisPage() {
      console.log('-------------setPageTitleOfThisPage ---------------');
      this.setPageTitle(this.pageTitle);
    },

    /**
     * Rest api emmitter function to fetch data query.
     */
    async fetchDataQueryFromRestApiEmitter(itemObject) {
      if (itemObject) {
        console.log(
          '============ fetchDataQueryFromRestApiEmitter ===== ',
          itemObject,
        );
        const response = await this.fetchDataQueryDetailsFromApi(
          itemObject.restUrlParam,
          itemObject.dataQueryIdForApi,
          true,
        );
        console.log('------ fetchDataQueryFromRestApiEmitter response ----- ', response);
        console.log(
          '============ fetchDataQueryFromRestApiEmitter : 111 ===== ',
          this.paginationMetaData,
        );
        console.log(
          '============ fetchDataQueryFromRestApiEmitter : 333 ===== ',
          this.dataQueryDetailList,
        );

        if (itemObject.userActionType === this.USER_LIST_ACTION_TYPE_CSV_DOWNLOAD) {
          // Method to download CSV file.
          downloadAsCsvFile([...this.dataQueryDetailList], itemObject.fileName);
        } else if (itemObject.userActionType === this.USER_LIST_ACTION_TYPE_JSON_VIEW) {
          this.jsonViewerDialog = true;
        }
      }
    },

    /**
     * Download data as csv file.
     */
    async downloadAsCsvFileEmitter(item) {
      this.dataQueryDetailList.splice(0);
      console.log('---------- User List : downloadAsCsvFileEmitter ', item);
      if (item && Object.prototype.hasOwnProperty.call(item, 'id')) {
        if (item.queryStringType === this.APP_ADMIN_QUERY_TYPE_SQL) {
          console.log(
            '------------ UserList SQL : downloadAsCsvFileEmitter ********** ',
            item.id,
          );
          this.dataQueryDetailList = await this.findQueryDetailForAdmin(
            item.id,
          );
          console.log(
            '------------ UserList SQL Result : dataQueryDetailList ********** ',
            this.dataQueryDetailList,
          );
        } else if (item.queryStringType === this.APP_ADMIN_QUERY_TYPE_JSON) {
          const response = await this.fetchDataQueryDetailsFromApi(
            item.queryString,
            item.id,
            true,
          );
          console.log(
            '------ downloadAsCsvFileEmitter : Json : Response  ----- ',
            response,
          );
          console.log(
            '============ downloadAsCsvFileEmitter Json Res : 111 ===== ',
            this.paginationMetaData,
          );
          console.log(
            '============ downloadAsCsvFileEmitter Json Res : 333 ===== ',
            this.dataQueryDetailList,
          );
        }

        // Method to download CSV file.
        const fileName = `${item.displayName}_${item.id}`;
        downloadAsCsvFile([...this.dataQueryDetailList], fileName);
      }
    },

    /**
     * Download data as csv file.
     */
    async viewDataQueryAsJsonFileEmitter(item) {
      console.log(
        '---------- User List : viewDataQueryAsJsonFileEmitter ',
        item,
      );
      if (item && Object.prototype.hasOwnProperty.call(item, 'id')) {
        if (item.queryStringType === this.APP_ADMIN_QUERY_TYPE_SQL) {
          console.log(
            '------------ UserList SQL : viewDataQueryAsJsonFileEmitter : SQL ********** ',
            item.id,
          );
          this.dataQueryDetailList = await this.findQueryDetailForAdmin(
            item.id,
          );
          console.log(
            '------------ UserList SQL Result : dataQueryDetailList ********** ',
            this.dataQueryDetailList,
          );
        } else if (item.queryStringType === this.APP_ADMIN_QUERY_TYPE_JSON) {
          console.log(
            '------------ UserList SQL : viewDataQueryAsJsonFileEmitter : JSON ********** ',
            item.id,
          );
          const response = await this.fetchDataQueryDetailsFromApi(
            item.queryString,
            item.id,
            true,
          );
          console.log(
            '------ viewDataQueryAsJsonFileEmitter : Json : Response  ----- ',
            response,
          );
          console.log(
            '============ viewDataQueryAsJsonFileEmitter Json Res : 111 ===== ',
            this.paginationMetaData,
          );
          console.log(
            '============ viewDataQueryAsJsonFileEmitter Json Res : 333 ===== ',
            this.dataQueryDetailList,
          );
        }
        this.jsonViewerDialog = true;
      }
    },
  },
};
</script>
<style scoped lang="scss">
.fontSize13px {
  font-size: 13px !important;
}

.withBoldFont {
  @extend .fontSize13px;
  font-weight: bold;
}
</style>
