/**
 * Function to convert objectArray to CSV.
 * @param {*} objArray
 */
function convertToCSV(objArray) {
  const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
  const CSV_COLUMN_SEPERATOR = ';';
  let str = '';

  if (array && array.length > 0) {
    let headerLine = '';
    const items = Object.keys(array[0]);
    (items).forEach((key) => {
      if (headerLine !== '') headerLine += CSV_COLUMN_SEPERATOR;
      headerLine += `"${key}"`;
    });
    str += `${headerLine}\r\n`;

    for (let i = 0; i < array.length; i++) { // eslint-disable-line
      let line = '';
      for (const index in array[i]) { // eslint-disable-line
        if (line !== '') line += CSV_COLUMN_SEPERATOR;
        let columnStr = array[i][index];
        if (columnStr && columnStr.toString().indexOf('"') > -1) {
          columnStr = (columnStr).replaceAll('"', '""');
        }
        line += `"${columnStr}"`;
      }
      str += line + '\r\n'; // eslint-disable-line
    }
  }

  return str;
}

/**
 * Export CSV file.
 *
 * @param {*} headers
 * @param {*} items
 * @param {*} fileTitle
 */
export function exportCSVFile(dataItemList, fileName) {
  if (fileName && dataItemList && dataItemList.length > 0) {
    const jsonObject = JSON.stringify(dataItemList);
    const csv = convertToCSV(jsonObject);
    const exportedFilenmae = fileName + '.csv' || 'export.csv'; // eslint-disable-line
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    if (navigator.msSaveBlob) { // IE 10+
      navigator.msSaveBlob(blob, exportedFilenmae);
    } else {
      const link = document.createElement('a');
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', exportedFilenmae);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  }
}

export default exportCSVFile;
